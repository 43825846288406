export const ifActions = [
  // {
  //     instr_type: 500,
  //     clickValue: "first",
  //     instr: 'sample condition "<span class="sp-e label">X</span>" to "<span class="sp-e context">Y</span>"',
  //     variables: [{ name: "x_label", idx: 2 }, { name: "y_label", idx: 4 }],
  // },
  // {
  //     instr_type: 501,
  //     clickValue: "second",
  //     instr: 'till "<span class="sp-e label">X</span>"',
  //     variables: [{ name: "x_label", idx: 2 }],
  // },
  // {
  //     instr_type: 502,
  //     clickValue: "third",
  //     instr: 'if'
  // }
  // {
  //     instr_type: 2200,
  //     instr: 'If "<span class="sp-e label">condition</span>"',
  //     variables: [{ name: "condition_instr", idx: 2 }],
  // },
  {
    instr_type: 90,
    clickValue: "first",
    instr: 'verify "<span class="sp-e label">Text</span>" is on screen',
    variables: [{ name: "label", idx: 1 }],
    // "label_type": LabelTypes.normal,
    // "label": "status",
  },
  {
    instr_type: 91,
    clickValue: "second",
    instr:
      'verify "<span class="sp-e label">Text</span>" after "<span class="sp-e context">Text</span>"',
    variables: [
      { name: "label", idx: 1 },
      { name: "context", idx: 3 },
    ],
    // "label_type": LabelTypes.normal,
    // "label": "status",
    // "contextual_label_type": LabelTypes.global_data,
    // "contextual_label": "details",
  },
  {
    instr_type: 93,
    clickValue: "third",
    instr:
      'verify "<span class="sp-e label">Text</span>" for "<span class="sp-e context">Text</span>"',
    variables: [
      { name: "label", idx: 1 },
      { name: "context", idx: 3 },
    ],
  },
  {
    instr_type: 94,
    clickValue: "fourth",
    instr:
      'verify "<span class="sp-e label">Text</span>" before "<span class="sp-e context">Text</span>"',
    variables: [
      { name: "label", idx: 1 },
      { name: "context", idx: 3 },
    ],
    //   "label_type": LabelTypes.normal,
    // "label": "status",
    // "contextual_label_type": LabelTypes.global_data,
    // "contextual_label": "details",
  },

  // verify (Check) and asset(confirm)
  {
    instr_type: 95,
    // data: "some_data",
    // expected_value: "expected_value",
    instr:
      'verify "<span class="sp-e label">some_data</span>" is "<span class="sp-e context">expected_value</span>"',
    clickValue: "fifth",
    variables: [
      { name: "label", idx: 1 },
      { name: "context", idx: 3 },
    ],
  },

  {
    instr_type: 96,
    // data: "some_data",
    // expected_value: "expected_value",
    instr:
      'verify "<span class="sp-e label">some_data</span>" contains "<span class="sp-e context">expected_value</span>"',
    clickValue: "sixth",
    variables: [
      { name: "label", idx: 1 },
      { name: "context", idx: 3 },
    ],
  },
  {
    instr_type: 97,
    // data: "some_data",
    // expected_value: "expected_value",
    instr:
      'verify "<span class="sp-e label">some_data</span>" starts with "<span class="sp-e context">expected_value</span>"',
    clickValue: "seventh",
    variables: [
      { name: "label", idx: 1 },
      { name: "context", idx: 4 },
    ],
  },
  {
    instr_type: 98,
    // data: "some_data",
    // expected_value: "expected_value",
    instr:
      'verify "<span class="sp-e label">some_data</span>" ends with "<span class="sp-e context">expected_value</span>"',
    clickValue: "eighth",
    variables: [
      { name: "label", idx: 1 },
      { name: "context", idx: 4 },
    ],
  },
  {
    instr_type: 99,
    // data: "some_data",
    // expected_value: "expected_value",
    instr:
      'verify "<span class="sp-e label">some_data</span>" is case insensitively equal to "<span class="sp-e context">expected_value</span>"',
    clickValue: "ninth",
    variables: [
      { name: "label", idx: 1 },
      { name: "context", idx: 7 },
    ],
  },
];

export const IfInstrType = 2200;
export const ElseInstrType = 2202;

// function getInstrTypes(actions) {
//     return actions.map(action => action.instr_type);
// }

// const instrTypes = getInstrTypes(ifActions);

export function doesIfInstrTypeExist(type) {
  return IfInstrType == type;
}

export function getIfInstruction(
  instr_type,
  label = null,
  context = null,
  label_type = null,
  context_type = null
) {
  const instructions = [
    {
      instr_type: 90,
      instr: "verify label is on screen",
      label: null,
      label_type: 0,
      label_default: "Text",
    },
    {
      instr_type: 91,
      instr: "verify label after context",
      label: null,
      context: null,
      label_type: 0,
      context_type: 0,
      label_default: "Text",
      context_default: "Text",
    },
    {
      instr_type: 93,
      instr: "verify label for context",
      label: null,
      context: null,
      label_type: 0,
      context_type: 0,
      label_default: "Text",
      context_default: "Text",
    },
    {
      instr_type: 94,
      instr: "verify label before context",
      label: null,
      context: null,
      label_type: 0,
      context_type: 0,
      label_default: "Text",
      context_default: "Text",
    },
    {
      instr_type: 95,
      instr: "verify label is context",
      label: null,
      context: null,
      label_type: 0,
      context_type: 0,
      label_default: "some_data",
      context_default: "expected_value",
    },
    {
      instr_type: 96,
      instr: "verify label contains context",
      label: null,
      context: null,
      label_type: 0,
      context_type: 0,
      label_default: "some_data",
      context_default: "expected_value",
    },
    {
      instr_type: 97,
      instr: "verify label starts with context",
      label: null,
      context: null,
      label_type: 0,
      context_type: 0,
      label_default: "some_data",
      context_default: "expected_value",
    },
    {
      instr_type: 98,
      instr: "verify label starts ends with context",
      label: null,
      context: null,
      label_type: 0,
      context_type: 0,
      label_default: "some_data",
      context_default: "expected_value",
    },
    {
      instr_type: 99,
      instr: "verify label is case insensitively equal to context",
      label: null,
      context: null,
      label_type: 0,
      context_type: 0,
      label_default: "some_data",
      context_default: "expected_value",
    },
  ];

  const instruction = instructions.find(
    (instr) => instr.instr_type === instr_type
  );

  if (instruction) {
    if (label !== null) {
      instruction.label = label;
      instruction.label_type = label_type;
    }
    if (context !== null) {
      instruction.context = context;
      instruction.context_type = context_type;
    }
  }

  return instruction;
}

// "inst": {
//     "instr": "If comdition",
//     "instr_type": 2200,
//     "condition_instr": {
//         "inst": {
//             "label": "Web Tables",
//             "instr_type": 90,
//             "label_type": 0,
//             "actual_label": "Web Tables",
//             "contextual_label": "",
//             "contextual_label_type": 0,
//             "actual_contextual_label": ""
//         }
//     }
// },

// "inst": {
//     "instr": "verify \"Text\" is on screen",
//     "label": "dsdsds",
//     "instr_type": 90,
//     "label_type": 0,
//     "actual_label": "dsdsds"
// },
