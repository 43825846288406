import { doesIfInstrTypeExist } from "../pages/EcomTesting/TestSteps/dropdowns/data/if";

export function findIdOfCardAbove(step_id) {
    const parts = step_id.split('.').map(Number);

    if (parts.length === 1) {
        return (parts[0] - 1).toString();
    }

    const lastPart = parts[parts.length - 1];
    if (lastPart > 1) {
        parts[parts.length - 1] = lastPart - 1;
    } else {
        parts.pop();
    }

    return parts.join('.');
}

// function to find the parent "if" card of a given card
const isIfCard = (card) => doesIfInstrTypeExist(card.inst.instr_type);

export function findParentIf(cards, currentStepId) {

  // Helper function to get the parent step ID
  function getParentStepId(stepId) {
    const parts = stepId.split('.');
    return parts.length > 1 ? parts.slice(0, -1).join('.') : null;
  }

  function findCardAndIndex(cards, id) {
    for (let i = 0; i < cards.length; i++) {
      if (cards[i].step_id === id) {
        return { card: cards[i], index: i };
      }
      if (cards[i].sub_steps && cards[i].sub_steps.length > 0) {
        const result = findCardAndIndex(cards[i].sub_steps, id);
        if (result) return result;
      }
    }
    return null;
  }

  const result = findCardAndIndex(cards, currentStepId);

  if (result) {
    const { card, index } = result;
    const parentCards = getParentStepId(card.step_id) ? 
      findCardAndIndex(cards, getParentStepId(card.step_id)).card.sub_steps : 
      cards;

    for (let i = index - 1; i >= 0; i--) {
      if (isIfCard(parentCards[i])) {
        return parentCards[i].step_id;
      }
    }
  } 

  else {
    const parentStepId = getParentStepId(currentStepId);
    const parentResult = parentStepId ? findCardAndIndex(cards, parentStepId) : { card: { sub_steps: cards }, index: -1 };
    
    if (parentResult) {
      const parentCards = parentResult.card.sub_steps || cards;
      const currentIndex = parentCards.findIndex(c => c.step_id === currentStepId);
      const startIndex = currentIndex !== -1 ? currentIndex - 1 : parentCards.length - 1;

      for (let i = startIndex; i >= 0; i--) {
        if (isIfCard(parentCards[i])) {
          return parentCards[i].step_id;
        }
      }
    }
  }

  return null; // No parent "if"
}
//function to extract the step id of card and its substeps
export function extractStepIds(step) {
  // Initialize an array to hold the step_ids
  let stepIds = [];

  // Add the current step_id to the array
  if (step.step_id) {
      stepIds.push(step.step_id);
  }

  // Check if there are sub_steps and recursively extract their step_ids
  if (step.sub_steps && step.sub_steps.length > 0) {
      for (const subStep of step.sub_steps) {
          stepIds = stepIds.concat(extractStepIds(subStep));
      }
  }

  return stepIds;
}


export const compareHierarchicalIds = (a, b) => {
  const partsA = a.split('.').map(Number);
  const partsB = b.split('.').map(Number);

  for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
    if (partsA[i] !== partsB[i]) {
      return partsA[i] - partsB[i];
    }
  }

  // If all parts so far are equal, shorter length comes first
  return partsA.length - partsB.length;
};



export const findCardById = (id, cards) => {
  for (const card of cards) {
    if (card.step_id === id) {
      return card;
    }
    if (card.sub_steps && card.sub_steps.length > 0) {
      const subCard = findCardById(id, card.sub_steps);
      if (subCard) {
        return subCard;
      }
    }
  }
  return null;
};

export const findParentId = (id) => {
  const idArray = id.split('.');
  return idArray.slice(0, -1).join('.');
} 


export const findNextCardId = (currentCardId, cards) => {
  // Split the current card ID into parts
  const currentIdParts = currentCardId.split('.');

  // Function to find the card recursively
  const findCardByIdRecursive = (currentCards, currentParts) => {
      // If we've reached the end of the current ID parts, return null
      if (currentParts.length === 0) return null;

      // Find the current level card
      const currentLevelIndex = currentParts.length === 1 
          ? parseInt(currentParts[0]) - 1 
          : currentParts.slice(0, -1).reduce((acc, part) => {
              const index = parseInt(part) - 1;
              return acc[index].sub_steps || [];
          }, cards);

      const currentCard = currentLevelIndex[parseInt(currentParts[currentParts.length - 1]) - 1];

      // If this is the last part of the ID, look for the next card at this level
      if (currentParts.length === 1 || currentParts.length === currentIdParts.length) {
          const parentCards = currentParts.length === 1 
              ? cards 
              : currentIdParts.slice(0, -1).reduce((acc, part) => {
                  const index = parseInt(part) - 1;
                  return acc[index].sub_steps || [];
              }, cards);

          // Find the index of the current card
          const currentIndex = parentCards.findIndex(card => 
              card.step_id === currentCardId || 
              (currentCard && card.step_id === currentCard.step_id)
          );

          // Look for the next card at the same level
          for (let i = currentIndex + 1; i < parentCards.length; i++) {
              if (parentCards[i].step_id) return parentCards[i].step_id;
          }

          return null;
      }

      // If there are sub_steps, drill down
      if (currentCard && currentCard.sub_steps && currentCard.sub_steps.length > 0) {
          const nextId = findCardByIdRecursive(
              currentCard.sub_steps,
              currentParts.slice(1)
          );

          // If we found a next card in sub_steps, return it
          if (nextId) return nextId;
      }

      return null;
  };

  // Call the recursive function
  return findCardByIdRecursive(cards, currentIdParts);
};
