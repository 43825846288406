import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import './customInstructionConstructor.css';

const CustomInstructionConstructor = () => {
  const [sentence, setSentence] = useState('');
  const [words, setWords] = useState([]);
  const [dropdownOptions] = useState([
    'Object', 'Action', 'Attribute', 'Condition', 'Location'
  ]);

  const handleTextChange = (e) => {
    setSentence(e.target.value);
    const wordList = e.target.value.split(/(\s+)/).map(word => ({
      text: word,
      isEditable: false,
      assigned_value: null
    }));
    setWords(wordList);
  };

  const handleDoubleClick = (index) => {
    const newWords = [...words];
    newWords[index].isEditable = true;
    setWords(newWords);
  };

  const handleDropdownSelect = (index, value) => {
    const newWords = [...words];
    newWords[index].isEditable = false;
    newWords[index].assigned_value = value;
    setWords(newWords);
  };

  const handleReset = (index) => {
    const newWords = [...words];
    newWords[index].isEditable = true;
    newWords[index].assigned_value = null;
    setWords(newWords);
  };

  return (
    <div className="instruction-maker">
      <div className="input-container">
        <input 
          type="text" 
          value={sentence}
          onChange={handleTextChange}
          placeholder="Type your instruction..."
        />
        <EditIcon className="edit-icon" />
      </div>
      <div className="words-container">
        {words.map((word, index) => {
          if (word.text.trim() === '') return <span key={index}>{word.text}</span>;

          if (word.isEditable) {
            return (
              <div key={index} className="editable-word">
                <select 
                  onChange={(e) => handleDropdownSelect(index, e.target.value)}
                  className="dropdown"
                >
                  <option value="">Select Value</option>
                  {dropdownOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
                <CloseIcon 
                  className="reset-icon" 
                  onClick={() => handleReset(index)}
                />
              </div>
            );
          }

          if (word.assigned_value) {
            return (
              <Tooltip key={index} title={word.assigned_value}>
                <div 
                  className="assigned-word"
                  onClick={() => handleReset(index)}
                >
                  <span>{word.text}</span>
                  <CheckIcon className="check-icon" />
                </div>
              </Tooltip>
            );
          }

          return (
            <span 
              key={index}
              onDoubleClick={() => handleDoubleClick(index)}
              className="word"
            >
              {word.text}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default CustomInstructionConstructor;