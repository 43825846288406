import apiService, { fileService } from "./apiServices";

const getPath = (path) => {
  return "/platform/" + path;
};

// get all file details
export const fetchAllFiles = async () => {
  try {
    const response = await apiService.get(getPath("artifacts"));
    if (response.status !== 200 || !response.data) {
      //throw new Error("Failed to fetch files details");
    }
    return response.data;
  } catch (error) {
    console.error("Error while fetching files details", error);
    //throw error;
  }
};
export const fetchuserinfo = async () => {
  try {
    const response = await apiService.get(getPath("userinfo"));
    if (response.status !== 200 || !response.data) {
      //throw new Error("Failed to fetch files details");
    }
    return response.data;
  } catch (error) {
    console.error("Error while fetching files details", error);
    //throw error;
  }
};

// Add asset file (formData must contain name & file)
export const addAssetFile = async (formData) => {
  try {
    const response = await fileService.post(getPath("artifact"), formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (!response.data) {
      throw new Error("Failed to upload asset file");
    }
    return response.data;
  } catch (error) {
    console.error("Error while uploading asset file", error);
    return error?.response?.data ?? error?.response ?? error;
    // throw error;
    // return error && error?.response
    //   ? error?.response?.data
    //     ? error.response.data
    //     : error.response
    //   : error;
  }
};

// Edit asset file (formData can contain name or file)
export const editAssetFile = async (formData, id, name) => {
  let response;
  try {
    response = await fileService.patch(
      getPath(`artifact/${id}${name && name.length ? "?name=" + name : ""}`),
      formData,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (!response.data) {
      throw new Error("Failed to upload asset file");
    }
    return response.data;
  } catch (error) {
    console.error("Error while uploading asset file", error);
    // throw error;
    return error?.response?.data || error;
  }
};

// delete asset file by id
export const deleteAssetFile = async (id) => {
  try {
    const response = await apiService.delete(getPath(`artifact/?id=${id}`));
    if (response.status !== 200 || !response.data) {
      throw new Error("Failed to delete asset file");
    }
    return response.data;
  } catch (error) {
    console.error("Error while deleting asset file", error);
    throw error;
  }
};

// download asset file by id
export const downloadArtifact = async (id) => {
  try {
    const response = await apiService.get(getPath("artifact/download"), {
      params: { id },
      responseType: "arraybuffer",
    });
    if (response.status !== 200 || !response.data) {
      throw new Error("Failed to download asset file");
    }
    return response;
  } catch (error) {
    console.error("Error while deleting asset file", error);
    throw error;
  }
};
